<template>
  <div>
    <label class="upload-file-cursor text-primary" @click="uploadClick()">
      <img class="mr-3" :src="resolve_img_url('cloud-upload-outline.svg')" />
      <label class="camelCase">{{ this.$props.description }}</label>
    </label>

    <div class="box-file box-file-max">
      <div>
        {{ name }}
        <i
          v-show="showDeleteButton"
          v-on:click="removeFileRef()"
          class="fas fa-times text-primary ml-1 upload-file-cursor"
        ></i>
        <b-progress
          v-show="showProgress"
          :value="progress"
          :max="max"
          show-value
          class="mb-3"
        ></b-progress>
      </div>
    </div>
    <input
      type="file"
      ref="file"
      class="input-file"
      v-on:change="handleFileUpload()"
      capture="user"
    />
    <div
      class="invalid"
      v-show="
        !this.$props.state && this.$props.state != null && this.$props.required
      "
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ this.$props.msgErro ? this.$props.msgErro : this.msgErroInvalid }}
    </div>
  </div>
</template>

<script>
import resolve_img_url from "../../../../utils/image/image-utils";
import {
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { getFileRef } from "../../../../services/training/trainingUploadFileService";
import { documentPDFUpload } from '../../../../services/training/trainingService';

export default {
  name: "upload-file-with-progress",
  props: [
    "description",
    "state",
    "msgErro",
    "required",
    "trainingName",
    "fileUploaded",
    "uploadFileMicroService",
  ],
  async mounted() {
    this.uploadByFileMicroService = this.$props.uploadFileMicroService; 
  },
  created() {
    this.$root.$refs.UploadFile = this;
    if (this.$props.fileUploaded) {
      this.progress = 0;
      this.max = 100;
      this.name = this.$props.fileUploaded.name
        ? this.$props.fileUploaded.name
        : "file";
      this.fileLink = this.$props.fileUploaded.link;
      this.showProgress = false;
      this.showDeleteButton = true;
    }
  },
  watch: {
    fileUploaded: {
      handler() {
        if (this.$props.fileUploaded) {
          this.progress = 0;
          this.max = 100;
          this.name = this.$props.fileUploaded.name
            ? this.$props.fileUploaded.name
            : "file";
          this.fileLink = this.$props.fileUploaded.link;
          this.showProgress = false;
          this.showDeleteButton = true;
        }
      },
    },
  },
  methods: {
    async uploadClick() {
      this.$refs.file.click();
    },
    resolve_img_url: resolve_img_url,
    handleFileUpload() {
      this.showProgress = true;
      var files = [...this.$refs.file.files];
      const names = files.map((o) => o.name);
      files = files.filter(
        ({ name }, index) => !names.includes(name, index + 1)
      );
      this.name = names[0];
      this.enableBucketDeletion = true;
      this.$emit("startUploadFile", this.name);

      this.fileRef = getFileRef(`training/${this.trainingName}/${this.name}`);

      if (this.uploadByFileMicroService) {
        documentPDFUpload(files[0], this.trainingName).then((response) => {
          this.showProgress = false;
            this.showDeleteButton = true;
            this.fileLink = response.link;
            this.$emit("updateFile", response.link, files[0].name);
        })
        .catch((error) => {
          console.error("error upload file: ", error);
          this.$emit("errorUploadingFile", error);
        })
      } else {
        const uploadTask = uploadBytesResumable(this.fileRef, files[0]);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.$emit("errorUploadingFile", error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              this.showProgress = false;
              this.showDeleteButton = true;
              this.fileLink = downloadURL;
              this.$emit("updateFile", downloadURL, files[0].name);
            });
          }
        );
      }
    },
    reset() {
      this.fileRef = null;
      this.progress = 0;
      this.name = "";
      this.showProgress = false;
      this.showDeleteButton = false;
    },
    removeFileRef() {
      if (this.fileRef && this.enableBucketDeletion) {
        deleteObject(this.fileRef)
          .then(() => {
            this.reset();
            this.$emit("fileRemoved", { link: this.fileLink });
          })
          .catch((error) => {
            this.$emit("errorDeletingFile", error);
          });
      } else {
        this.$emit("markToDeletion", this.name);
        this.$emit("fileRemoved", { link: this.fileLink });
        this.reset();
      }
    },
  },
  data() {
    return {
      progress: 0,
      max: 100,
      name: "",
      fileLink: "",
      currentIndex: 0,
      fileRef: null,
      showProgress: false,
      showDeleteButton: false,
      msgErroInvalid: "É necessário anexar um arquivo",
      uploadByFileMicroService: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-file-cursor {
  cursor: pointer;
  font-size: multiplier-default-font-size(3);
  align-items: center;
}

.input-file {
  display: none;
}

.box-file-max {
  max-height: 12rem;
}

.box-file-min {
  max-height: 5rem;
}

.box-file {
  min-height: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.invalid {
  color: var(--danger-2);
}
</style>
