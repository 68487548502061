import { render, staticRenderFns } from "./UploadFileBucket.vue?vue&type=template&id=2fc5c752&scoped=true&"
import script from "./UploadFileBucket.vue?vue&type=script&lang=js&"
export * from "./UploadFileBucket.vue?vue&type=script&lang=js&"
import style0 from "./UploadFileBucket.vue?vue&type=style&index=0&id=2fc5c752&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc5c752",
  null
  
)

export default component.exports