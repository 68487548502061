<template>
  <div>
    <label class="upload-file-cursor text-primary" @click="uploadClick()">
      <img class="mr-3" :src="resolve_img_url('cloud-upload-outline.svg')" />
      <label class="camelCase">{{ this.$props.description }}</label>
    </label>

    <div class="box-file box-file-max">
      <div v-for="(file, index) in files" :key="file.link">
        <div>
          {{ file.name }}
          <i
            v-show="file.showDeleteButton"
            v-on:click="removeFileByIndex(index)"
            class="fas fa-times text-primary ml-1 upload-file-cursor"
          ></i>

          <b-progress
            v-show="file.showProgress"
            :value="file.progress"
            :max="max"
            show-value
            class="mb-3"
          ></b-progress>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="file"
      class="input-file"
      v-on:change="handleFileUpload()"
      capture="user"
    />
    <div
      class="invalid"
      v-show="
        !this.$props.state && this.$props.state != null && this.$props.required
      "
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ this.$props.msgErro ? this.$props.msgErro : this.msgErroInvalid }}
    </div>
  </div>
</template>

<script>
import resolve_img_url from "../../../../utils/image/image-utils";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { documentPDFUpload } from '../../../../services/training/trainingService';

export default {
  name: "upload-file-with-progress",
  props: [
    "description",
    "state",
    "msgErro",
    "required",
    "trainingName",
    "filesUploaded",
    "uploadFileMicroService",
  ],
  created() {
    this.$root.$refs.UploadFile = this;
    this.files = this.$props.filesUploaded;
  },
  async mounted() {
    this.uploadByFileMicroService = this.$props.uploadFileMicroService; 
  },
  methods: {
    async uploadClick() {
      this.$refs.file.click();
    },
    resolve_img_url: resolve_img_url,
    handleFileUpload() {
      var files = [...this.$refs.file.files];
      const names = files.map((o) => o.name);
      files = files.filter(
        ({ name }, index) => !names.includes(name, index + 1)
      );

      const storage = getStorage();
      const refFile = ref(storage, `training/${this.trainingName}/${names[0]}`) 

      let file = {
        showProgress: true,
        progress: 0,
        link: "",
        name: names[0],
        enableBucketDeletion: true,
        fileRef: refFile,
      };

      this.files.push(file);

      file.fileRef.fileIndex = this.files.indexOf(file);
      this.$emit("startUploadFile", this.files);

      if (this.uploadByFileMicroService) {
        documentPDFUpload(files[0], this.trainingName).then((response) => {
          file.showProgress = false;
          file.link = response.link;
          file.showDeleteButton = true;
          this.$emit("updateFile", this.files);
        })
        .catch((error) => {
          console.error("error upload file: ", error);
          file.showProgress = false;
            this.$emit("errorUploadingFile", error);
        })
      } else {
        file.uploadTask = uploadBytesResumable(file.fileRef, files[0]);

        file.uploadTask.on(
          "state_changed",
          (snapshot) => {
            let currentFile = this.files[snapshot.ref.fileIndex];
            currentFile.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            file.showProgress = false;
            this.$emit("errorUploadingFile", error);
          },
          () => {
            getDownloadURL(file.uploadTask.snapshot.ref).then((downloadURL) => {
              file.showProgress = false;
              file.link = downloadURL;
              file.showDeleteButton = true;
              this.$emit("updateFile", this.files);
            });
          }
        );
      }
    },
    removeFileByIndex(index) {
      this.currentIndex = index;
      const fileRemoved = this.files[index];
      let ref = fileRemoved.fileRef;

      if (fileRemoved.enableBucketDeletion) {
        deleteObject(ref)
          .then(() => {
            this.files.splice(this.currentIndex, 1);
            this.$emit("fileRemoved", this.files);
          })
          .catch((error) => {
            this.$emit("errorDeletingFile", error);
          });
      } else {
        this.files.splice(this.currentIndex, 1);
        this.$emit("fileRemoved", this.files);
        this.$emit("markToDeletion", fileRemoved.name);
      }
    },
  },
  data() {
    return {
      progress: 0,
      max: 100,
      files: [],
      name: "",
      currentIndex: 0,
      fileRef: null,
      showProgress: false,
      showDeleteButton: false,
      msgErroInvalid: "É necessário anexar um arquivo",
      uploadByFileMicroService: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-file-cursor {
  cursor: pointer;
  font-size: multiplier-default-font-size(3);
  align-items: center;
}

.input-file {
  display: none;
}

.box-file-max {
  max-height: 12rem;
}

.box-file-min {
  max-height: 5rem;
}

.box-file {
  min-height: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.invalid {
  color: var(--danger-2);
}
</style>
