<template>
  <div>
    <CustomModalTemplate
      modalId="trail-files-modal"
      title="Arquivos"
      ref="form-modal"
      noCloseOnBackdrop
    >
      <template slot="header"><h4>Arquivos</h4></template>

      <template slot="content">
        <b-form id="alter-form" class="alter-form">
          <b-card class="custom-card my-4" border-variant="light">
            <b-form-row v-for="item in fileList" :key="item.nrOrder">
              <b-col>
                {{ item.fileName || "file" }}
              </b-col>
              <b-col>
                {{
                  item.principal ? "arquivo principal" : "arquivo complementar"
                }}
              </b-col>
              <b-col class="justify-content-end">
                <div>
                  <a :href="item.link" target="_blank">
                    <img
                      class="button-custom"
                      src="@/assets/icons/download-outline.svg"
                    />
                  </a>
                </div>
              </b-col>
            </b-form-row>
          </b-card>
        </b-form>
      </template>

      <template slot="footer">
        <b-button
          variant="primary"
          class="ml-4"
          @click="$bvModal.hide('trail-files-modal')"
        >
          Fechar
        </b-button>
      </template>
    </CustomModalTemplate>
  </div>
</template>

<script>
import CustomModalTemplate from "../../../../components/custom-modal/CustomModalTemplate.vue";

export default {
  props: ["fileList"],
  components: { CustomModalTemplate },
};
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 5px;
  &-size {
    color: var(--green-0);
    height: 3rem;
    width: 2.2rem;
  }
}

.button:hover {
  cursor: pointer;
}

.button-custom:hover {
  cursor: pointer;
}

.disable {
  opacity: 0.5;
}
</style>
